import React from 'react'

import { Wrapper, SEO } from '@renderbus/common/components'
import {
  ContentContainerNew,
  TitleImg,
  PrizeListNew,
  PrizeItemNew,
  RulesList,
  DescriptionList,
  StepImgWrapNew,
  MidYearRechargeContent,
  RulesItemTitle,
  RulesItem,
  DescriptionItem,
  MyLotteryContent,
  LotteryCard,
  ActivityButton,
  PopupOverlay,
  PopupContent,
  PopupButton,
  PopupContentTitle,
  PopupContentText,
  PopExit,
  RCStyle,
  PrizeContent,
  JoinNowButton,
} from './ysdh-mid-year-recharge.atom'
import { API, Axios } from '@renderbus/common/utils'
import { isTest } from '@renderbus/common/service'

import PrizeImage1 from '../images/ysdh-mid-year-recharge/prize/prize-1.png'
import PrizeImage2 from '../images/ysdh-mid-year-recharge/prize/prize-2.png'
import PrizeImage3 from '../images/ysdh-mid-year-recharge/prize/prize-3.png'
import PrizeImage4 from '../images/ysdh-mid-year-recharge/prize/prize-4.png'
import PrizeImage5 from '../images/ysdh-mid-year-recharge/prize/prize-5.png'
import PrizeImage6 from '../images/ysdh-mid-year-recharge/prize/prize-6.png'
import PrizeImage7 from '../images/ysdh-mid-year-recharge/prize/prize-7.png'
import PrizeImage8 from '../images/ysdh-mid-year-recharge/prize/prize-8.png'
import ActivityRules from '../images/ysdh-mid-year-recharge/activity-rules-1.png'
import ActivityDisplay from '../images/ysdh-mid-year-recharge/activity-display-1.png'
import ActivityDesc from '../images/ysdh-mid-year-recharge/activity-desc-1.png'
import MyLottery from '../images/ysdh-mid-year-recharge/my-lottery-1.png'
import stepPC from '../images/ysdh-mid-year-recharge/midyear-2024-step.png'
import stepMobile from '../images/ysdh-mid-year-recharge/midyear-2024-mobile-step.png'
import SurveyIcon from '../images/ysdh-mid-year-recharge/survey.png'
import SurveyAcitveIcon from '../images/ysdh-mid-year-recharge/survey-active.png'
import CouponIcon from '../images/ysdh-mid-year-recharge/coupon.png'
import CouponActiveIcon from '../images/ysdh-mid-year-recharge/coupon-active.png'
import GiftIcon from '../images/ysdh-mid-year-recharge/gift.png'
import GiftActiveIcon from '../images/ysdh-mid-year-recharge/gift-active.png'
import RechargeGift from '../images/ysdh-mid-year-recharge/recharge-gift.png'
import RechargeHandIcon from '../images/ysdh-mid-year-recharge/recharge-hand.gif'

import Layout from '../molecules/layout'
import { AnimationMiddleBanner } from '../molecules/banner'

const prizeImgList = [
  {
    name: '英伟达 RTX A4000显卡 ×1',
    subname: '',
    src: PrizeImage1,
  },
  {
    name: 'iPhone 15（128GB）×2',
    subname: '',
    src: PrizeImage2,
  },
  {
    name: '大疆 DJI Osmo Action4',
    subname: '灵眸运动相机全能套装 ×3',
    src: PrizeImage3,
  },
  {
    name: '任天堂 Switch NS掌上游戏机',
    subname: 'OLED主机日版彩色 ×4',
    src: PrizeImage4,
  },
  {
    name: 'ROG魔导士 NX轴冰川蓝轴',
    subname: '无线机械键盘 ×5',
    src: PrizeImage5,
  },
  {
    name: '华为手环9 NFC版',
    subname: '智能手环 ×20',
    src: PrizeImage6,
  },
  {
    name: '飞利浦 3204G专业多功能',
    subname: '按摩筋膜枪 ×30',
    src: PrizeImage7,
  },
  {
    name: 'Renderbus 定制U型枕',
    subname: '（参与可得）',
    src: PrizeImage8,
  },
]
const activityNotes = [
  '如对本活动有任何问题，可以微信添加瑞云小助手（微信号：rayvision1）进行咨询',
  '青云平台用户不参与本次活动，若动画用户企业账号参与活动，则以主账号为准',
  '每个用户通过充值最多可获得30张抽奖券；没有中奖的客户可凭一张券兑换一份周边，多券多换',
  '已中奖的用户不可再兑参与奖，但如果用户超过15张抽奖券且只获六等奖的情况下，可单独找业务经理申请兑换剩余奖券的参与奖（封顶15套），用户超过10张抽奖券且只获七等奖的情况下，可单独找业务经理申请兑换剩余奖券的参与奖（封顶20套）',
  `已充值5000元以上的用户，登录后可点击导航栏<img src=${RechargeGift} alt="" />图标，查看已获得的抽奖券和问卷，开奖后也可以到该页面填写兑奖信息`,
  '只有已经获得抽奖券的用户才能填写问卷，获得更多奖券码',
  '抽奖奖品一律不支持折换现金，未在截止日期前兑换奖品的用户视为放弃奖品',
  '本次活动最终解释权归深圳市瑞云科技股份有限公司所有，瑞云有权对恶意刷抢活动资源、利用活动资源从事违法违规行为的用户收回活动参与资格，并对涉事账号进行封禁处理！',
]

class MidYearRecharge2021 extends React.PureComponent {
  state = {
    isOpen: false,
    closeActivity: false,
  }
  constructor(props) {
    super(props)
    this.handleSumbit = this.handleSumbit.bind(this)
    this.toLottery = this.toLottery.bind(this)
  }
  componentDidMount() {
    let nowTime = new Date()
    let closeTime = new Date('2024-06-26T12:00:00')
    if (nowTime > closeTime) {
      this.setState({
        closeActivity: true,
      })
    }
  }
  instance = new Axios(isTest() ? API.auth.testHost : API.auth.host)
  rechargeUrl = `https://${isTest() ? 'account-pre' : 'account'}.renderbus.com/center/user/topUp`

  async handleSumbit() {
    const hasActivityQualification = await this.instance.post(API.event.getSupportActivity)
    if (hasActivityQualification && [602, 603].includes(hasActivityQualification.code)) {
      window.location.href = this.rechargeUrl
      return
    }
    if (!(hasActivityQualification.data && hasActivityQualification.data.length)) return
    const { code, data } = await this.instance.post(API.event.getUserPrizeCode, {
      activityId: hasActivityQualification.data[0].id,
    })

    if (data && code === 200) {
      if (!data.length) window.location.href = this.rechargeUrl
      if (data && data.filter((item) => item.payRecordId).length) {
        window.location.href = `${
          isTest() ? 'http://pre.renderbus.com:8081' : 'https://www.renderbus.com'
        }/nvidia-form.html?activityId=${hasActivityQualification.data[0].id}`
      }
    }
  }
  async toLottery() {
    let nowTime = new Date()
    let closeTime = new Date('2024-07-12T18:00:00')
    if (nowTime > closeTime) {
      return
    }
    const link = `https://${isTest() ? 'account-pre' : 'account'}.renderbus.com/center/user/topUp`
    let searchParams = '?openCashPrize=true'
    try {
      const res = await this.instance.post(API.auth.queryUser)
      localStorage.setItem('animationRes', JSON.stringify(res))
      if (res.code === 200) {
        const isAnimationUser = res.data.businessType === 1
        if (!isAnimationUser) {
          searchParams = ''
        }
      }
    } catch (e) {
      window.location.href = `${link}${searchParams}`
    }
    window.location.href = `${link}${searchParams}`
  }

  touchMoveHandler = function (e) {
    e.preventDefault()
  }
  handleClose = () => {
    this.setState({
      isOpen: false,
    })
    document.body.style.overflow = 'auto'
    document.body.style.paddingRight = ''
    document.body.removeEventListener('touchmove', this.touchMoveHandler)
  }
  handleOpen = () => {
    // this.setState({
    //   isOpen: true,
    // })
    // let scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
    // document.body.style.overflow = 'hidden'
    // document.body.style.paddingRight = scrollBarWidth + 'px'
    // document.body.addEventListener('touchmove', this.touchMoveHandler, { passive: false })
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    const { isOpen, closeActivity } = this.state
    return (
      <Layout location={location} topThreeShare={topThreeShare} style={{ background: 'yellow' }}>
        <SEO
          title='「影视动画云渲染」年中充值大促,限时福利抢先享-瑞云渲染'
          keywords='影视动画云渲染充值活动,影视动画抽奖活动,瑞云渲染充值活动'
          description='Renderbus瑞云渲染推出2024年中充值豪礼活动，只要您是影视动画用户，在单笔充值满5000元得一张抽奖券，每位用户最多得30张。通过抽奖券抽奖有机会得专业显卡、iPhone15、大疆运动相机等奖品，快来参与活动赢取大奖吧！'
          sharePostSlug='ysdh-mid-year-recharge.html'
        />
        <AnimationMiddleBanner
          buttonLabel='立即充值'
          buttonHref={`https://${
            isTest() ? 'account-pre' : 'account'
          }.renderbus.com/center/user/topUp`}
          buttonID='ysdhnzhd-ljcz'
          isBanner={false}
        />
        <MidYearRechargeContent>
          <Wrapper>
            <ContentContainerNew top={32} mobileTop={'0'} className='prize-detail'>
              <TitleImg src={ActivityDisplay} alt='奖品详情' />
              <PrizeContent>
                <PrizeListNew>
                  {prizeImgList.map((item, index) => {
                    return (
                      <PrizeItemNew key={item.name}>
                        <img src={item.src} alt={item.name} />
                        <div>
                          <p className='webPriceTitle'>{item.name}</p>
                          <p className='sub-name'>{item.subname}</p>
                        </div>
                      </PrizeItemNew>
                    )
                  })}
                </PrizeListNew>
                <JoinNowButton
                  as='a'
                  id='ysdhnzhd-ljcy'
                  href={`https://${
                    isTest() ? 'account-pre' : 'account'
                  }.renderbus.com/center/user/topUp`}
                >
                  立即参与
                </JoinNowButton>
              </PrizeContent>
            </ContentContainerNew>
            <ContentContainerNew className='activity-rule'>
              <TitleImg src={ActivityRules} alt='活动规则' />
              <StepImgWrapNew>
                <img src={stepPC} alt='' className='pc' />
                <img src={stepMobile} alt='' className='mb' />
              </StepImgWrapNew>
              <RulesList>
                <RulesItem>
                  <RulesItemTitle>参与方式</RulesItemTitle>
                  <div>
                    活动期间，影视动画用户单笔充值<span>每满5000元</span>
                    ，即可获得1张抽奖券，获得奖券后再<span>填写问卷</span>
                    即可额外多获得1张抽奖券，每个用户通过充值最多可获得<span>30张</span>抽奖券；
                  </div>
                </RulesItem>
                <RulesItem>
                  <RulesItemTitle>活动时间</RulesItemTitle>
                  <div>2024年5月16日10:00～2024年6月26日12:00，6月27日晚20:00统一直播抽奖</div>
                </RulesItem>
                <RulesItem>
                  <RulesItemTitle>活动范围</RulesItemTitle>
                  <div>Renderbus影视动画用户，青云平台用户不参与本次活动</div>
                </RulesItem>
                <RulesItem>
                  <RulesItemTitle>开奖时间</RulesItemTitle>
                  <div>2024年6月27日晚20:00，B站直播间（@Renderbus瑞云渲染 ）统一直播开奖</div>
                </RulesItem>
                <RulesItem>
                  <RulesItemTitle>兑奖方式</RulesItemTitle>
                  <div>
                    用户中奖后，请在瑞云渲染官网活动页面-兑奖专区-立即兑奖，或者点击官网充值页面导航栏
                    <img src={RechargeGift} alt='' />
                    图标处，查看奖券并填写相关兑奖信息进行兑奖
                  </div>
                </RulesItem>
                <RulesItem>
                  <RulesItemTitle>兑奖时间</RulesItemTitle>
                  <div>2024年6月28日10点开放兑奖链接</div>
                </RulesItem>
                <RulesItem>
                  <RulesItemTitle>兑奖截止时间</RulesItemTitle>
                  <div>2024年7月12日18:00</div>
                </RulesItem>
              </RulesList>
            </ContentContainerNew>
            <ContentContainerNew className='activity-desc'>
              <TitleImg src={ActivityDesc} alt='活动说明' />
              <DescriptionList>
                {activityNotes.map((note) => (
                  <DescriptionItem key={note} dangerouslySetInnerHTML={{ __html: note }} />
                ))}
              </DescriptionList>
            </ContentContainerNew>
            <ContentContainerNew>
              <TitleImg src={MyLottery} alt='我的瑞云抽奖' />
              <MyLotteryContent>
                <LotteryCard
                  id='ysdhnzhd-txwj'
                  onClick={closeActivity ? this.handleOpen : this.handleSumbit}
                >
                  <img src={SurveyIcon} alt='' />
                  <img src={SurveyAcitveIcon} alt='' className='active' />
                  <div>
                    <div className='title'>获取更多奖券</div>
                    <div className='text'>填写调查问卷</div>
                    <div className='text'>可额外获一张抽奖券</div>
                  </div>
                </LotteryCard>
                <LotteryCard id='ysdhnzhd-djck' onClick={this.toLottery}>
                  <img src={CouponIcon} alt='' />
                  <img src={CouponActiveIcon} alt='' className='active' />
                  <div>
                    <div className='title'>我的奖券码</div>
                    <div className='text'>点击查看</div>
                    <div className='text'>已获得奖券码及中奖情况</div>
                  </div>
                </LotteryCard>
                <LotteryCard id='ysdhnzhd-djdhjp' onClick={this.toLottery}>
                  <img src={GiftIcon} alt='' />
                  <img src={GiftActiveIcon} alt='' className='active' />
                  <div>
                    <div className='title'>兑奖专区</div>
                    <div className='text'>点击兑换奖品</div>
                  </div>
                </LotteryCard>
              </MyLotteryContent>
            </ContentContainerNew>
            <ActivityButton
              // disabled={true}
              id='ysdhnzhd-ljcyhd'
              as='a'
              href={`https://${
                isTest() ? 'account-pre' : 'account'
              }.renderbus.com/center/user/topUp`}
            >
              立即参与活动，抽取壕华礼品！
              <img className='hand' src={RechargeHandIcon} alt='' />
            </ActivityButton>
          </Wrapper>
        </MidYearRechargeContent>
        {/* {isOpen && closeActivity && (
          <PopupOverlay>
            <PopupContent>
              <PopupContentTitle top='69px'>活动已结束</PopupContentTitle>
              <PopupContentText>
                我们将在2024年6月27日晚20:00Renderbus瑞云渲染 B站直播间开奖，敬请关注！
              </PopupContentText>
              <PopupButton onClick={this.handleClose}>我知道了</PopupButton>
              <PopExit onClick={this.handleClose}></PopExit>
            </PopupContent>
          </PopupOverlay>
        )} */}
        <RCStyle />
      </Layout>
    )
  }
}

export default MidYearRecharge2021
