import styled, { createGlobalStyle } from 'styled-components'
import { Media, typography } from '@renderbus/common/theme'
import { hideSMDown, mbSize } from '@renderbus/common/theme/mixin'
import { Button } from '@renderbus/common/components'
import PopBg from '../images/ysdh-mid-year-recharge/pop-bg.png'
import SandBg from '../images/ysdh-mid-year-recharge/sand-bg.jpg'
import CrabImg from '../images/ysdh-mid-year-recharge/crab.png'
import StarfishImg from '../images/ysdh-mid-year-recharge/starfish.png'

export const RCStyle = createGlobalStyle`
  html {
    background-image: url(${SandBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    background-position: 0 -410px;
    ${Media.lessThan(Media.small)} {
      background-image: unset;
      background-color: rgba(242, 230, 206, 1);
    }
  }
`

export const ContentContainerNew = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1400px;
  margin: ${p => (p.top ? p.top + 'px' : '157px')} 0 0 0;
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 15px 26px 5px rgba(204, 146, 31, 0.1);
  &.prize-detail,
  &.activity-desc {
    &::after {
      content: '';
      width: 128px;
      height: 128px;
      position: absolute;
      background-image: url(${CrabImg});
      background-size: 100% 100%;
      right: -62px;
      bottom: -65px;
    }
  }
  &.activity-rule {
    &::before {
      content: '';
      width: 72px;
      height: 72px;
      position: absolute;
      background-image: url(${StarfishImg});
      background-size: 100% 100%;
      left: -26px;
      bottom: -36px;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(700)};
    margin: ${p => (p.mobileTop ? mbSize(p.mobileTop) : mbSize(148))} 0 0 0;
    border-radius: ${mbSize(12)};
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
`
export const MidYearRechargeContent = styled.div`
  /* background-image: url(${SandBg});
  background-size: 100% 100%; */
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(-290)};
  }
`

export const ParticipationWayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 170px;
`
export const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 170px;
`
export const TitleImg = styled.img`
  height: 107px;
  position: absolute;
  top: -53px;
  z-index: 1;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(128)};
    top: ${mbSize(-60)};
  }
`
export const StepImgWrapNew = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 118px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .pc {
    width: 1120px;
  }
  .mb {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    justify-content: flex-start;
    padding: ${mbSize(102)} ${mbSize(36)} 0px;
    .pc {
      display: none;
    }
    .mb {
      width: ${mbSize(462)};
      margin: auto;
      display: block;
    }
  }
`
export const PrizeContent = styled.div`
  padding: 100px 55px 80px 55px;
  .crab-img {
    width: 124px;
    height: 124px;
  }
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(100)} ${mbSize(30)};
  }
`
export const PrizeListNew = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${Media.lessThan(Media.small)} {
  }
`
export const PrizeItemNew = styled.div`
  width: 300px;
  text-align: center;
  margin-bottom: 60px;
  img {
    width: 300px;
  }
  div {
    margin-top: 28px;
  }
  p {
    margin: 0;
    line-height: 24px;
    font-size: 16px;
    color: #111111;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(314)};
    margin-bottom: ${mbSize(60)};
    img {
      width: ${mbSize(300)};
    }
    div {
      margin-top: ${mbSize(40)};
    }
    p {
      margin: 0;
      line-height: ${mbSize(36)};
      font-size: ${mbSize(24)};
    }
  }
`
export const JoinNowButton = styled(Button)`
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: linear-gradient(180deg, #ff9933 0%, #ff4c6a 100%);
  border-radius: 30px;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
  margin-top: 20px;
  &:hover {
    box-shadow: 0px 10px 18px 2px rgba(204, 146, 31, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(320)};
    height: ${mbSize(68)};
    line-height: ${mbSize(68)};
    border-radius: ${mbSize(34)};
    font-size: ${mbSize(30)};
    margin-top: ${mbSize(20)};
  }
`
export const RulesItemTitle = styled.div`
  color: #994c26;
  margin-right: 5px;
  white-space: pre;
  &:before {
    content: '【 ';
  }
  &:after {
    content: ' 】';
  }
`
export const RulesItem = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 36px;
  img {
    width: 130px;
    margin: -1px 5px 0;
  }
  span {
    color: #12cc75;
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    line-height: ${mbSize(36)};
    img {
      width: ${mbSize(130)};
      margin: 0 ${mbSize(5)};
    }
  }
`
export const RulesList = styled.div`
  color: #111111;
  width: 1096px;
  margin: 79px 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(650)};
    margin: 4.42vh 0;
  }
`
export const DescriptionItem = styled.li`
  color: #111111;
  font-size: 16px;
  line-height: 36px;
  img {
    width: 130px;
    height: 30px;
    margin: -1px 5px 0;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    line-height: ${mbSize(36)};
    img {
      width: ${mbSize(130)};
      height: ${mbSize(30)};
      margin: 0 ${mbSize(5)};
    }
  }
`
export const DescriptionList = styled.ol`
  width: 1158px;
  padding: 117px 0 81px 0;
  margin: 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(645)};
    padding: ${mbSize(100)} ${mbSize(5)} ${mbSize(80)} ${mbSize(37)};
  }
`
export const QrcodeList = styled.span`
  display: flex;
  margin: 40px 0;
  ${Media.lessThan(Media.small)} {
    margin: 20px 0;
  }
`
export const QrcodeWrap = styled.span`
  width: 210px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
  }
  span {
    margin-top: 11px;
    text-align: center;
  }
  .mbQrcode {
    display: none;
  }
  .webQrcode {
    ${hideSMDown}
  }
  ${Media.lessThan(Media.small)} {
    width: 32%;
    font-size: ${typography.textThin};
    img {
      width: 4.5rem;
      height: 4.5rem;
    }
    .mbQrcode {
      display: block;
    }
  }
`

export const LotteryCard = styled.div`
  width: 240px;
  height: 240px;
  background: rgba(18, 204, 117, 0.05);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  img {
    height: 68px;
    margin-top: 35px;
    &.active {
      display: none;
    }
  }
  > div {
    margin-top: 40px;
    text-align: center;
    .title {
      color: #111111;
      margin-bottom: 11px;
      font-size: 18px;
      line-height: 18px;
    }
    .text {
      color: #666666;
      font-size: 16px;
      line-height: 22px;
    }
  }
  &:hover {
    background: linear-gradient(45deg, #29cc7a 0%, #26bf4c 100%);
    box-shadow: 0px 10px 18px 2px rgba(204, 146, 31, 0.3);
    border: 2px solid #ffecb2;
    img {
      &:first-child {
        display: none;
      }
      &.active {
        display: block;
      }
    }
    .title {
      color: #ffecb2;
      font-weight: bold;
    }
    .text {
      color: #ffffff;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(200)};
    height: ${mbSize(240)};
    img {
      height: ${mbSize(68)};
      margin-top: ${mbSize(35)};
    }
    > div {
      margin-top: ${mbSize(32)};
      .title {
        font-size: ${mbSize(18)};
        margin-bottom: ${mbSize(20)};
        line-height: ${mbSize(18)};
      }
      .text {
        font-size: ${mbSize(16)};
        line-height: ${mbSize(24)};
      }
    }
  }
`
export const MyLotteryContent = styled.div`
  width: 100%;
  padding: 118px 150px 80px;
  display: flex;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(100)} ${mbSize(26)} ${mbSize(80)};
  }
`

export const ActivityButton = styled(Button)`
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 120px 0 220px 0;
  width: 460px;
  height: 80px;
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
  line-height: 80px;
  text-align: center;
  padding: unset;
  background: linear-gradient(180deg, #ff9933 0%, #ff4c6a 100%);
  border-radius: 40px;
  .hand {
    position: absolute;
    right: -57px;
    top: 57px;
    width: 115px;
    height: 100px;
  }
  &:hover {
    box-shadow: 0px 15px 26px 5px rgba(15, 102, 102, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(520)};
    height: ${mbSize(70)};
    line-height: ${mbSize(70)};
    font-size: ${mbSize(28)};

    margin: 5.99vh 0 11.16vh 0;
    &:hover {
      box-shadow: 0px 15px 26px 5px rgba(204, 146, 31, 0.3);
    }
    .hand {
      width: ${mbSize(128)};
      height: ${mbSize(110)};
      right: ${mbSize(-76)};
      top: ${mbSize(39)};
    }
  }
`
export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

export const PopupContent = styled.div`
  max-width: 600px;
  max-height: 340px;
  text-align: center;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    height: 340px;
    background-image: url(${PopBg});
    background-size: cover;
    z-index: -1;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(660)};
    height: ${mbSize(400)};
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${mbSize(660)};
      height: ${mbSize(400)};
      background-image: url(${PopBg});
      background-size: 100% 100%;
      z-index: -1;
    }
  }
`

export const PopupButton = styled.div`
  width: 160px;
  height: 40px;
  background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
  box-shadow: 0px 6px 11px 1px rgba(64, 194, 93, 0.3);
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  margin-left: calc(50% - 80px);
  margin-top: 47px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(53)};
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
    border-radius: ${mbSize(30)};
    line-height: ${mbSize(60)};
    font-size: ${24};
    margin-left: calc(50% - ${mbSize(120)});
  }
`

export const PopupContentTitle = styled.div`
  margin-top: ${props => (props.top ? props.top : '0px')};
  font-size: 30px;
  font-weight: bold;
  color: #13cb75;
  line-height: 30px;
  text-align: center;
  box-sizing: border-box;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(42)};
    margin-top: ${mbSize(69)};
  }
`
export const PopupContentText = styled.div`
  margin-top: 29px;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  padding: 0px 80px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(26)};
    line-height: ${mbSize(42)};
    max-width: ${mbSize(607)};
    margin-top: ${mbSize(41)};
    padding: 0 ${mbSize(26)};
  }
`
export const PopExit = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #999999;
  height: 17px;
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #999999;
  }
  ::before {
    transform: rotate(45deg);
  }
  ::after {
    transform: rotate(-45deg);
  }
  ${Media.lessThan(Media.small)} {
    right: ${mbSize(29)};
    top: ${mbSize(20)};
  }
`
